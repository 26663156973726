import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { DomSanitizer } from '@angular/platform-browser';

import SharedService from '../../shared/shared.service';
import { LAB_LOCATIONS, LAB_LOCATIONS_TITLE } from '../../shared/constants/pages.constants';
import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'lab-locations',
  templateUrl: './lab-locations.component.html',
  styleUrls: []
})
export class LabLocationsComponent extends BaseComponent {
  constructor(sharedService: SharedService
    , public sanitizer: DomSanitizer
    , private titleService: Title,
    route: ActivatedRoute, router: Router) {
    super(sharedService, route, router);
    this.uid = LAB_LOCATIONS;
    this.titleService.setTitle(LAB_LOCATIONS_TITLE);
  }
}
