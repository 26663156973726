<section class="content section">
  <div class="container" *ngIf="page">
    <h5 style="color:lightblue" ><i>Search Results of {{searchText}}</i> </h5>
    <!--<div class="m-b-sm m-t-sm" *ngIf="page.notification">
    <div class="ql-editor notification" [innerHTML]="page.notification | safeHtml">
      </div>
     </div>

    <div class="m-b-sm m-t-sm" *ngIf="page.description">
      <div class="editor ql-editor" [innerHTML]="page.description | safeHtml">
      </div>
    </div>

    <div class="m-b-sm m-t-sm" *ngIf="page.content_display_type && page.content_display_type.display_type_uid == contentDisplayType.LIST">
    <list [contents]="contents" [contentTemplate]="contentTemplate" [loadContentTemplate]="true"></list>
  </div>-->
    <!--<div class="m-b-sm m-t-sm" *ngIf="page.content_display_type">
    <accordion *ngIf="page.content_display_type.display_type_uid == contentDisplayType.ACCORDION" [contents]="contents"
               [contentTemplate]="contentTemplate" [subcontentTemplate]="subcontentTemplate" [loadContentTemplate]="true"
               [loadSubcontentTemplate]="true" [firstLevel]="firstLevel" [secondLevel]="secondLevel" [thirdLevel]="thirdLevel"></accordion>

    <tabs *ngIf="page.content_display_type.display_type_uid == contentDisplayType.TABS" [contents]="contents"
          [selectedTitle]="selectedTitle" [selectedSubTitle]="selectedSubTitle" [contentTemplate]="contentTemplate"
          [subcontentTemplate]="subcontentTemplate" [loadContentTemplate]="true" [loadSubcontentTemplate]="true"></tabs>
  </div>-->



    <div class="m-b-sm m-t-sm">
      <div *ngIf="contents.length > 0">
        <div *ngFor="let content of contents" class="mb-5" [id]="content.content_uid">
          <a href={{content.url}} title={{content.page.title}}> <div style="background-color:#f5f5f5!important; padding:10px 0; color:black; font-size:x-large; font-weight:600">  {{content.title}}  <span style="font-size:medium">({{content.page.title}})</span> </div> </a>
          <div [innerHTML]="content.description | safeHtml"></div>


          <div *ngIf="content.subcontents.length > 0" style="padding:0 0 0 60px ">
            <div *ngFor="let subcontent of content.subcontents" class="mb-5" [id]="content.content_uid">

              <div style="background-color:#f5f5f5!important; padding:5px 0; color:black; font-size:x-large; font-weight:200">
                {{subcontent.title}}
              </div>

              <div [innerHTML]="subcontent.description | safeHtml"></div>

            </div>

          </div>

        </div>
      </div>
    </div>


    <hr *ngIf="contents.length > 0 && pages.length > 0" style="    border: 3px solid hsl(201,95%,56%)"/>


    <div class="m-b-sm m-t-sm">
      <div *ngIf="pages.length > 0">
        <div *ngFor="let page of pages" class="mb-5" [id]="page.uid">
          <a href={{page.url}} title={{page.title}}> <div style="background-color:#f5f5f5!important; padding:10px 0; color:black; font-size:x-large; font-weight:600">  {{page.title}}   </div> </a>
          <div [innerHTML]="page.description | safeHtml"></div>
          <div [innerHTML]="page.notification | safeHtml"></div>
        </div>
      </div>
    </div>


    <!--<ng-template #contentTemplate let-content="content">-->
    <!--<div class="m-b-sm m-t-sm">
    <div *ngIf="contents.length > 0">
      <div *ngFor="let content of contents" class="mb-5" [id]="content.content_uid">
        {{content.subcontents.length}}
        <div *ngIf="content.subcontents.length">


          <div *ngFor="let subcontent of content.subcontents; let i = index" class="mb-5" [id]="subcontent.content_uid">
            <div class="columns">
              <div class="column is-narrow">
                <div class="box" style="height:75%; width: 155px;">
                  <p>
                    <a [href]="subcontent.url" target="_blank">-->
    <!--<img *ngIf="subcontent.images.length" class="is-rounded" [src]="_cmsApiUrl + subcontent.images[0].url">-->
    <!--<img *ngIf="subcontent.url" class="is-rounded" [src]="subcontent.url">
                  </a>
                </p>
              </div>
            </div>
            <div class="column">
              <div class="box" style="height:75%">
                <p class="title is-5"><span [style.color]="subcontent.title_color ? subcontent.title_color.code : ''">{{subcontent.title}}</span></p>
                <div class="mb-3" [innerHTML]="subcontent.description | safeHtml"></div>
                <p style="color:rgb(255, 153, 0)" class="title is-7">
                  {{subcontent.datetime | date:'EE, MMM d, yyyy'}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    </div>
  </div>-->
    <!--</ng-template>-->

  </div>
</section>
