import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";

import SharedService from '../../shared/shared.service';
import { CONFOCAL } from '../../shared/constants/pages.constants';
import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-confocal',
  templateUrl: './confocal.component.html',
  styleUrls: ['./confocal.component.css']
})
export class ConfocalComponent extends BaseComponent {

  constructor(sharedService: SharedService
    , private titleService: Title,
    route: ActivatedRoute, router: Router) {
    super(sharedService, route, router);
    this.uid = CONFOCAL;
    this.titleService.setTitle("CONFOCAL");
  }

  selectTab(tabName: string) {
    this.selectedTitle = tabName;
  }
}
