import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";

import SharedService from '../../shared/shared.service';
import { GUIDELINES, GUIDELINES_TITLE } from '../../shared/constants/pages.constants';
import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'guidelines',
  templateUrl: './guidelines.component.html',
  styleUrls: []
})
export class GuidelinesComponent extends BaseComponent {
  constructor(sharedService: SharedService,
    private titleService: Title,
    route: ActivatedRoute, router: Router
  ) {
    super(sharedService, route, router);
    this.uid = GUIDELINES;
    this.titleService.setTitle(GUIDELINES_TITLE);
  }
}
