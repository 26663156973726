<footer class="footer p-4">
  <div class="container">
    <div class="content is-size-7">
      <div class="columns">
        <div class="column has-text-left">
          <p>
            Connect with us:
            <fa-icon [icon]="faPhone" class="mr-1"></fa-icon>800-525-2225
            <a [routerLink]="['/lab-locations']" class="ml-2">
              <fa-icon [icon]="faMapMarker"></fa-icon>
              Locations
            </a>
          </p>
          
          
            <a [routerLink]="['/about-us']">
              About us
            </a> |
            <a [routerLink]="['/news']">
              News & Information
            </a> |
            <a [routerLink]="['/events']">
              Events
            </a> |
            <a [routerLink]="['/contact-us']">
              Contact us
            </a>
          
        </div>
        <div class="column has-text-centered p-0">
          <!--<figure class="image">-->
            <img src="../../assets/images/MSKCC_logo_hor_s_rev_rgb_150.png" class="mt-3" style="height:80px">
          <!--</figure>-->
        </div>
        <div class="column has-text-right">

          <p class="mb-0">
            <a [routerLink]="['/legal-disclaimer']">
              Legal disclaimer
            </a> |
            <a [routerLink]="['/privacy-policy']">
              Privacy policy
            </a> |
            <a [routerLink]="['/public-notices']">
              Public notices
            </a> |
            <a [routerLink]="['/sitemap']">
              Sitemap
            </a>
          </p>
          <p>
            © 2020 Memorial Sloan Kettering Cancer Center
          </p>
        </div>
      </div>
    </div>
  </div>
</footer>
