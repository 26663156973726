<section class="content section">
  <div class="container" *ngIf="page">
    <h3 class="title">
      {{page.title}}
    </h3>
    <div class="m-b-sm m-t-sm" *ngIf="page.notification">
      <div class="ql-editor notification" [innerHTML]="page.notification | safeHtml">
      </div>
    </div>
    <div class="m-b-sm m-t-sm" *ngIf="page.description">
      <div class="editor ql-editor" [innerHTML]="page.description | safeHtml">
      </div>
    </div>
    <div class="m-b-sm m-t-sm" *ngIf="page.content_display_type">
      <accordion *ngIf="page.content_display_type.display_type_uid == contentDisplayType.ACCORDION" [contents]="contents"
                 [contentTemplate]="contentTemplate" [subcontentTemplate]="subcontentTemplate" [loadContentTemplate]="true"
                 [loadSubcontentTemplate]="true" [firstLevel]="firstLevel" [secondLevel]="secondLevel" [thirdLevel]="thirdLevel"></accordion>

      <tabs *ngIf="page.content_display_type.display_type_uid == contentDisplayType.TABS" [contents]="contents"
            [selectedTitle]="selectedTitle" [selectedSubTitle]="selectedSubTitle" [contentTemplate]="contentTemplate"
            [subcontentTemplate]="subcontentTemplate" [loadContentTemplate]="true" [loadSubcontentTemplate]="true"></tabs>
    </div>
  </div>
</section>

<ng-template #contentTemplate let-content="content">
  <div class="tile is-ancestor">
    <div class="tile is-vertical is-8">
      <div class="tile is-parent">
        <article class="tile is-child">
          <div class="content">
            <div [innerHTML]="content.description | safeHtml">
            </div>
          </div>
        </article>
      </div>
    </div>
    <div class="tile is-parent">
      <article class="tile is-child">
        <div class="content">
          <div class="content">
            <figure class="image">
              <!--<img  *ngIf="content.images.length" [src]="_cmsApiUrl + content.images[0].url" [alt]="content.title"/>-->
              <!--<img *ngIf="content.images.length" [src]="_cmsApiUrl + content.images[0].formats.thumbnail.url.replace('uploads','../uploads')" [alt]="content.title">-->
            </figure>
          </div>
        </div>
      </article>
    </div>
  </div>
</ng-template>

<ng-template #subcontentTemplate let-content="subcontent" let-selectedTitle="thirdlevel" let-firstLevel="firstlevel" let-secondLevel="secondlevel">
  <div [innerHTML]="content.description | safeHtml" class="mb-3">
  </div>
<ng-container *ngIf="content.subcontents.length > 0">
  <tabs-control [contents]="content.subcontents"
                [contentTemplate]="subcontentDataTemplate" [loadContentTemplate]="true"
                [selectedTitle]="selectedTitle"
                [firstLevel]="firstLevel"
                [secondLevel]="secondLevel"></tabs-control>
  
</ng-container>
</ng-template>

<ng-template #subcontentDataTemplate let-content="content">
  <div class="box" *ngIf="content.is_active">
    <div class="tile is-ancestor">
      <div class="tile is-vertical is-8">
        <div class="tile is-parent">
          <article class="tile is-child">
            <div class="content">
              <div [innerHTML]="content.description | safeHtml">
              </div>
            </div>
          </article>
        </div>
      </div>
      <div class="tile is-parent">
        <article class="tile is-child">
          <div class="content">
            <div class="content">
              <figure class="image">
                <!--<img *ngIf="content.images.length" [src]="_cmsApiUrl + content.images[0].url" [alt]="content.title" />-->
                <!--<img *ngIf="content.images.length" [src]="_cmsApiUrl + content.images[0].formats.thumbnail.url" [alt]="content.title">-->
              </figure>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</ng-template>
