import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";

import {
  IconDefinition,
  faPlayCircle
} from '@fortawesome/free-regular-svg-icons';

import SharedService from '../../shared/shared.service';
import { TEAM, TEAM_TITLE } from '../../shared/constants/pages.constants';
import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TeamComponent extends BaseComponent {
  public faPlay: IconDefinition = faPlayCircle;
  constructor(sharedService: SharedService
    , private titleService: Title,
    route: ActivatedRoute, router: Router) {
    super(sharedService, route, router);
    this.uid = TEAM;
    this.titleService.setTitle(TEAM_TITLE);
  }
}
