import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";

import SharedService from '../../shared/shared.service';
import { BIOSAFETY, BIOSAFETY_TITLE } from '../../shared/constants/pages.constants';
import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'biosafety',
  templateUrl: './biosafety.component.html',
  styleUrls: []
})
export class BiosafetyComponent extends BaseComponent {

  constructor(sharedService: SharedService
    , private titleService: Title,
    route: ActivatedRoute, router: Router) {
    super(sharedService, route, router);
    this.uid = BIOSAFETY;
    this.titleService.setTitle(BIOSAFETY_TITLE);
  }

  selectTab(tabName: string) {
    this.selectedTitle = tabName;
  }
}
