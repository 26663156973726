<mat-accordion multi>
  <mat-expansion-panel *ngFor="let content of contents" [id]="content.content_uid">
    <mat-expansion-panel-header>
      <mat-panel-title class="has-text-weight-semibold title is-6">
        <div [style.color]="content.title_color ? content.title_color.code : ''">
          {{content.title}}
        </div>
      </mat-panel-title>
      <!--<mat-panel-description>
      This is a summary of the content
    </mat-panel-description>-->
    </mat-expansion-panel-header>
    <div *ngIf="loadContentTemplate">
      <ng-container [ngTemplateOutlet]="contentTemplate"
                    [ngTemplateOutletContext]="{content:content}"></ng-container>
    </div>
    <div *ngIf="!loadContentTemplate">
      <div [innerHTML]="content.description | safeHtml">
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
