import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";

import SharedService from '../../shared/shared.service';
import { RNA_FISH } from '../../shared/constants/pages.constants';
import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-rna-fish',
  templateUrl: './rna-fish.component.html',
  styleUrls: ['./rna-fish.component.css']
})
export class RnaFishComponent extends BaseComponent {

  constructor(sharedService: SharedService
    , private titleService: Title,
    route: ActivatedRoute, router: Router) {
    super(sharedService, route, router);
    this.uid = RNA_FISH;
    this.titleService.setTitle("RNA FISH");
  }

  selectTab(tabName: string) {
    this.selectedTitle = tabName;
  }
}
