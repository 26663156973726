import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";

import SharedService from '../../shared/shared.service';
import { SLIDE_SCANNERS } from '../../shared/constants/pages.constants';
import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-slide-scanners',
  templateUrl: './slide-scanners.component.html',
  styleUrls: ['./slide-scanners.component.css']
})
export class SlideScannersComponent extends BaseComponent {

  constructor(sharedService: SharedService
    , private titleService: Title,
    route: ActivatedRoute, router: Router) {
    super(sharedService, route, router);
    this.uid = SLIDE_SCANNERS;
    this.titleService.setTitle("SLIDE SCANNERS");
  }

  selectTab(tabName: string) {
    this.selectedTitle = tabName;
  }
}
