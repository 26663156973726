<div>
  <div *ngFor="let content of contents">
    <div *ngIf="loadContentTemplate" class="box">
      <ng-container [ngTemplateOutlet]="contentTemplate"
                    [ngTemplateOutletContext]="{content:content}"></ng-container>
    </div>
    <div *ngIf="!loadContentTemplate" class="box">
      <div [innerHTML]="content.title | safeHtml" style="font-weight:bolder; background-color:lightgrey; padding: 15px"></div><br />
      <div [innerHTML]="content.description | safeHtml"> </div><p></p>
    </div>
    <p></p>
  </div>
</div>
