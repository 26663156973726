import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";

import SharedService from '../../shared/shared.service';
import { PUBLIC_NOTICES, PUBLIC_NOTICES_TITLE } from '../../shared/constants/pages.constants';
import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'public-notices',
  templateUrl: './public-notices.component.html',
  styleUrls: []
})
export class PublicNoticesComponent extends BaseComponent {

  constructor(sharedService: SharedService
    , private titleService: Title,
    route: ActivatedRoute, router: Router) {
    super(sharedService, route, router);
    this.uid = PUBLIC_NOTICES;
    this.titleService.setTitle(PUBLIC_NOTICES_TITLE);
  }

  selectTab(tabName: string) {
    this.selectedTitle = tabName;
  }
}
