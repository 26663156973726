<section class="content section">
  <div class="container" *ngIf="page">
    <!--<h3 class="title">
      {{page.title}}
    </h3>-->
    <div class="m-b-sm m-t-sm" *ngIf="page.notification">
      <div class="ql-editor notification" [innerHTML]="page.notification | safeHtml">
      </div>
    </div>
    <div class="m-b-sm m-t-sm" *ngIf="page.description">
      <div class="editor ql-editor" [innerHTML]="page.description | safeHtml">
      </div>
    </div>
    <div class="m-b-sm m-t-sm">
      <accordion [contents]="contents"></accordion>
    </div>
  </div>
  
  <div class="container">
    <div class="m-b-sm m-t-sm">
      <iframe height="1000" width="100%" frameborder="no" src="https://skifccf.shinyapps.io/SortCalculator/"> </iframe>
    </div>
  </div>
</section>
