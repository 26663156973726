import { Component, OnInit } from '@angular/core';
import { Title } from "@angular/platform-browser";

import { IContent } from '../../shared/model/model.index';
import { BaseComponent } from '../base.component';
import SharedService from '../../shared/shared.service';
import { FLOW_POST_ITS, FLOW_POST_ITS_TITLE } from '../../shared/constants/pages.constants';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'flow-post-its',
  templateUrl: './flow-post-its.component.html',
  styleUrls: []
})
export class FlowPostItsComponent extends BaseComponent implements OnInit {
  isSearching: boolean;
  searchText: string;
  isSearchTextFocus: boolean;
  filteredContent: IContent[] = <IContent[]>[];
  constructor(sharedService: SharedService
    , private titleService: Title,
    route: ActivatedRoute, router: Router) {
    super(sharedService, route, router);
    this.uid = FLOW_POST_ITS;
    this.titleService.setTitle(FLOW_POST_ITS_TITLE);
  }

  ngOnInit() {
    this.getPage();
    //this.getContent('datetime:DESC');
    this.getContent();
  }

  searchTextFocus() {
    this.isSearchTextFocus = !this.isSearchTextFocus;
  }

  onSearch() {
    this.isSearching = !this.isSearching;
    this.filteredContent = [];
    if (this.searchText.trim()) {
      this.contents.forEach(c => {
        let filteredSubContent = c.subcontents.filter(s => s.title.toLowerCase().indexOf(this.searchText.toLowerCase().trim()) >= 0 || s.description.toLowerCase().indexOf(this.searchText.toLowerCase().trim()) >= 0)

        if (filteredSubContent.length > 0) {
          let fC = { ...c };
          fC.subcontents = filteredSubContent

          this.filteredContent.push(fC);
        }
      });
    }

    this.isSearching = !this.isSearching;
  }

  onClear() {
    this.filteredContent = [];
    this.searchText = "";
  }
}
