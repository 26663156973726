import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";

import SharedService from '../../shared/shared.service';
import { IF_IHC } from '../../shared/constants/pages.constants';
import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-if-ihc',
  templateUrl: './if-ihc.component.html',
  styleUrls: ['./if-ihc.component.css']
})
export class IfIhcComponent extends BaseComponent {

  constructor(sharedService: SharedService
    , private titleService: Title,
    route: ActivatedRoute, router: Router) {
    super(sharedService, route, router);
    this.uid = IF_IHC;
    this.titleService.setTitle("IF IHC");
  }

  selectTab(tabName: string) {
    this.selectedTitle = tabName;
  }
}

