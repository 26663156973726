<section class="content section">
  <div class="container" *ngIf="page">
    <div class="m-b-sm m-t-sm">
      <div class="columns">
        <div class="column pb-0">
          <h3 class="title">
            {{page.title}}
          </h3>
        </div>
        <div class="column pb-0">
          <div class="field has-addons search-addon">
            <div class="control is-expanded">
              <input class="input search-addon" type="text" placeholder="Search" (focus)="searchTextFocus()" (blur)="searchTextFocus()"
                     (keyup.enter)="onSearch()" [(ngModel)]="searchText" />
            </div>
            <div class="control">
              <a id="search-button" *ngIf="!searchText" class="button is-search search-addon" (click)="onSearch()">
                <span class="icon">
                  <i class="mdi mdi-magnify mdi-24px"></i>
                </span>
              </a>
              <a id="search-button" *ngIf="searchText" class="button is-search search-addon" (click)="onClear()">
                <span class="icon">
                  <i class="mdi mdi-close mdi-24px"></i>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="m-b-sm m-t-sm" *ngIf="page.notification">
      <div class="ql-editor notification" [innerHTML]="page.notification | safeHtml">
      </div>
    </div>
    <div class="m-b-sm m-t-sm" *ngIf="page.description">
      <div class="editor ql-editor" [innerHTML]="page.description | safeHtml">
      </div>
    </div>

    <div class="m-b-sm m-t-sm" *ngIf="filteredContent.length == 0 && page.content_display_type && page.content_display_type.display_type_uid == contentDisplayType.ACCORDION">
      <accordion [contents]="contents" [contentTemplate]="contentTemplate" [loadContentTemplate]="true"
                 [loadContentTitleTemplate]="false" [loadSubcontentPanel]="false"></accordion>
    </div>

    <div class="m-b-sm m-t-sm" *ngIf="page.content_display_type && page.content_display_type.display_type_uid == contentDisplayType.TABS">
      <tabs [contents]="contents" [selectedTitle]="selectedTitle" [contentTemplate]="contentTemplate" [loadContentTemplate]="true"></tabs>
    </div>

    <div class="m-b-sm m-t-sm" *ngIf="page.content_display_type && page.content_display_type.display_type_uid == contentDisplayType.LIST">
      <list [contents]="contents" [contentTemplate]="contentTemplate" [loadContentTemplate]="true"></list>
    </div>

    <div class="m-b-sm m-t-sm" *ngIf="filteredContent.length > 0 && page.content_display_type && page.content_display_type.display_type_uid == contentDisplayType.ACCORDION">
      <accordion [contents]="filteredContent" [contentTemplate]="contentTemplate" [loadContentTemplate]="true"
                 [loadContentTitleTemplate]="false" [loadSubcontentPanel]="false"></accordion>
    </div>

  </div>
</section>

<ng-template #contentTemplate let-content="content">
  <div class="m-b-sm m-t-sm">
    <div *ngIf="content.subcontents.length">
      <div *ngFor="let subcontent of content.subcontents; let i = index" class="mb-5" [id]="subcontent.content_uid">
        <div class="columns">
          <div class="column is-narrow">
            <div class="box" style="height:75%; width: 155px;">
              <p>
                <a [href]="subcontent.url" target="_blank">
                  <!--<img *ngIf="subcontent.images.length" class="is-rounded" [src]="_cmsApiUrl + subcontent.images[0].url">-->
                  <img *ngIf="subcontent.url" class="is-rounded" [src]="subcontent.url">
                </a>
              </p>
            </div>
          </div>
          <div class="column">
            <div class="box" style="height:75%">
              <p class="title is-5"><span [style.color]="subcontent.title_color ? subcontent.title_color.code : ''">{{subcontent.title}}</span></p>
              <div class="mb-3" [innerHTML]="subcontent.description | safeHtml"></div>
              <p style="color:rgb(255, 153, 0)" class="title is-7">
                {{subcontent.datetime | date:'EE, MMM d, yyyy'}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
