import { Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef } from '@angular/core';
import { BaseComponent } from '../base.component';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'tabs-control',
  templateUrl: './tabs-control.component.html',
  styleUrls: []
})
export class TabsControlComponent extends BaseComponent implements OnInit {
  @Input() public selectedTitle: string;
  @Input() public firstLevel: string;
  @Input() public secondLevel: string;

  routePath: string;
  constructor(private route: ActivatedRoute,
    private location: Location  ) {
    super();

    this.route.url.subscribe(urlSegments => {
      this.routePath = urlSegments.length ? urlSegments[0].path.toLowerCase() : '';
    });

  }

  ngOnInit(): void {
    if (!this.selectedTitle)
      this.selectedTitle = this.contents[0].title;
  }

  selectTab(content: any) {
    this.selectedTitle = content.title;

    this.location.replaceState(`/${this.routePath}/${this.firstLevel}/${this.secondLevel}/${content.content_uid ? content.content_uid : this.selectedTitle}`);
  }
}
