<div class="box">
  <div class="tabs is-toggle is-fullwidth">
    <ul class="m-0">
      <li [ngClass]="{ 'is-active': (selectedTitle == content.title || selectedTitle == content.content_uid) }" (click)="selectTab(content)" *ngFor="let content of contents">
        <a>
          <span class="icon is-small"><i class="fas fa-image" aria-hidden="true"></i></span>
          <span>{{content.title}}</span>
        </a>
      </li>
    </ul>
  </div>
  <div>
    <section [ngClass]="{ 'show': (selectedTitle == content.title || selectedTitle == content.content_uid) }" class="ml-2 mr-2 tab-content hide" *ngFor="let content of contents">
      <div *ngIf="loadContentTemplate">
        <ng-container [ngTemplateOutlet]="contentTemplate"
                      [ngTemplateOutletContext]="{content:content}"></ng-container>
      </div>
      <div *ngIf="!loadContentTemplate">
        <div [innerHTML]="content.description | safeHtml">
        </div>
      </div>
    </section>
  </div>
</div>
