import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";

import SharedService from '../../shared/shared.service';
import { ABOUT_US, ABOUT_US_TITLE } from '../../shared/constants/pages.constants';
import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'about-us',
  templateUrl: './about-us.component.html',
  styleUrls: []
})
export class AboutUsComponent extends BaseComponent {

  constructor(sharedService: SharedService
    , private titleService: Title,
    route: ActivatedRoute, router: Router) {
    super(sharedService, route, router);
    this.uid = ABOUT_US;
    this.titleService.setTitle(ABOUT_US_TITLE);
  }

  selectTab(tabName: string) {
    this.selectedTitle = tabName;
  }
}
