import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from './material.module';
import { SafeHtmlPipe } from './pipe/safeHtml.pipe';
import { OembedPipe } from './pipe/OembedPipe.pipe';

import {
  NotFoundComponent,
  TabsComponent,
  AccordionComponent,
  ListComponent,
  AccordionControlComponent,
  TabsControlComponent
} from './shared.index';

import { SharedRoutes } from './shared.routes';

@NgModule({
  declarations: [
    NotFoundComponent,
    TabsComponent,
    AccordionComponent,
    ListComponent,
    AccordionControlComponent,
    TabsControlComponent,
    SafeHtmlPipe,
    OembedPipe
  ],
  imports: [
    BrowserModule,
    RouterModule.forChild(SharedRoutes),
    MaterialModule
  ],
  exports: [
    TabsComponent,
    AccordionComponent,
    ListComponent,
    AccordionControlComponent,
    TabsControlComponent,
    SafeHtmlPipe,
    OembedPipe
  ]
})
export class SharedModule { }
