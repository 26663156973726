import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";

import { BaseComponent } from '../base.component';
import SharedService from '../../shared/shared.service';
import { GOOD_FLOW_NOTES, GOOD_FLOW_NOTES_TITLE } from '../../shared/constants/pages.constants';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'good-flow-notes',
  templateUrl: './good-flow-notes.component.html',
  styleUrls: []
})
export class GoodFlowNotesComponent extends BaseComponent  {
  constructor(sharedService: SharedService
    , private titleService: Title,
    route: ActivatedRoute, router: Router) {
    super(sharedService, route, router);
    this.uid = GOOD_FLOW_NOTES;
    this.titleService.setTitle(GOOD_FLOW_NOTES_TITLE);
  }
}
