import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";

import { BaseComponent } from '../base.component';
import SharedService from '../../shared/shared.service';
import { SAMPLE_PREPARATION, SAMPLE_PREPARATION_TITLE } from '../../shared/constants/pages.constants';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'sample-preparation',
  templateUrl: './sample-preparation.component.html',
  styleUrls: []
})
export class SamplePreparationComponent extends BaseComponent {
  constructor(sharedService: SharedService
    , private titleService: Title,
    route: ActivatedRoute, router: Router) {
    super(sharedService, route, router);
    this.uid = SAMPLE_PREPARATION;
    this.titleService.setTitle(SAMPLE_PREPARATION_TITLE);
  }
}
