<section class="content section">
  <div class="container" *ngIf="page">
    <h3 class="page-title">
      {{page.title}}
    </h3>
    <div class="m-b-sm m-t-sm" *ngIf="page.notification">
      <div class="ql-editor notification" [innerHTML]="page.notification | safeHtml">
      </div>
    </div>
    <div class="m-b-sm m-t-sm" *ngIf="page.description">
      <div class="editor ql-editor" [innerHTML]="page.description | safeHtml">
      </div>
    </div>
    <div class="m-b-sm m-t-sm">
      <accordion [contents]="contents" [firstLevel]="firstLevel" [secondLevel]="secondLevel" [thirdLevel]="thirdLevel"></accordion>
    </div>
  </div>
</section>

