<section class="content section">
  <div class="container" *ngIf="page">
    <h3 class="title">
      {{page.title}}
    </h3>
    <div class="m-b-sm m-t-sm" *ngIf="page.notification">
      <div class="ql-editor notification" [innerHTML]="page.notification | safeHtml">
      </div>
    </div>
    <div class="m-b-sm m-t-sm" *ngIf="page.description">
      <div class="editor ql-editor" [innerHTML]="page.description | safeHtml">
      </div>
    </div>

    <div class="m-b-sm m-t-sm" *ngIf="page.content_display_type && page.content_display_type.display_type_uid == contentDisplayType.ACCORDION">
      <accordion [contents]="contents" [loadContentTemplate]="false"
                 [loadContentTitleTemplate]="false" ></accordion>
    </div>

    <div class="m-b-sm m-t-sm" *ngIf="page.content_display_type && page.content_display_type.display_type_uid == contentDisplayType.TABS">
      <tabs [contents]="contents" [selectedTitle]="selectedTitle" [selectedSubTitle]="selectedSubTitle" [loadContentTemplate]="false"></tabs>
    </div>

    <div class="m-b-sm m-t-sm" *ngIf="page.content_display_type && page.content_display_type.display_type_uid == contentDisplayType.LIST">
      <list [contents]="contents" [loadContentTemplate]="false"></list>
    </div>

  </div>
</section>
