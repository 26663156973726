<section class="section is-medium">
  <div class="container">
    <div class="columns is-vcentered">
      <div class="column has-text-centered">
        <h1 class="title">404</h1>
        <p class="subtitle">Looks like the page you were looking for is no longer here. Please contact the site owner.</p>
      </div>
    </div>
  </div>
</section>
