import { Component } from '@angular/core';
import { BaseComponent } from '../base.component';

import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'list',
  templateUrl: './list.component.html',
  styleUrls: []
})
export class ListComponent extends BaseComponent {
  constructor(route: ActivatedRoute,
    location: Location) {
    super(route, location);
  }
}
