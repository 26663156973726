import { Component, Input } from '@angular/core';
import { IContent, ISubcontent } from '../../model/model.index';
import { BaseComponent } from '../base.component';

import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'tabs',
  templateUrl: './tabs.component.html',
  styleUrls: []
})
export class TabsComponent extends BaseComponent {
  @Input() public selectedTitle: string;
  @Input() public selectedSubTitle: string;

  constructor(route: ActivatedRoute,
    location: Location) {
    super(route, location);
  }

  selectTab(content: IContent) {
    this.selectedTitle = content.title;
    if (content.subcontents.length > 0) {
      this.selectedSubTitle = content.subcontents[0].title;
    }

    if (this.selectedTitle && this.selectedSubTitle) {
      this.location.replaceState(`/${this.routePath}/${this.selectedTitle}/${this.selectedSubTitle}`);
    }
    else {
      this.location.replaceState(`/${this.routePath}/${this.selectedTitle}`);
    }
  }

  selectSubTab(subcontent: ISubcontent) {
    this.selectedSubTitle = subcontent.title;

    this.location.replaceState(`/${this.routePath}/${this.selectedTitle}/${this.selectedSubTitle}`);
  }
}
