<div class="box">
  <div class="tabs is-toggle is-fullwidth">
    <ul class="m-0">
      <li [ngClass]="{ 'is-active': selectedTitle == content.title }" (click)="selectTab(content)" *ngFor="let content of contents">
        <a>
          <span class="icon is-small"><i class="fas fa-image" aria-hidden="true"></i></span>
          <span>{{content.title}}</span>
        </a>
      </li>
    </ul>
  </div>
  <div>
    <section [ngClass]="{ 'show': selectedTitle == content.title }" class="ml-2 mr-2 tab-content hide" *ngFor="let content of contents">

      <div *ngIf="loadContentTemplate">
        <ng-container [ngTemplateOutlet]="contentTemplate"
                      [ngTemplateOutletContext]="{content:content}"></ng-container>
      </div>
      <div *ngIf="!loadContentTemplate">
        <div [innerHTML]="content.description | safeHtml">
        </div>
      </div>

      <div *ngIf="content.subcontents.length" class="tabs is-toggle is-fullwidth pt-4">
        <ul class="m-0">
          <li [ngClass]="{ 'is-active': selectedSubTitle == subcontent.title }" (click)="selectSubTab(subcontent)" *ngFor="let subcontent of content.subcontents">
            <a>
              <span class="icon is-small"><i class="fas fa-image" aria-hidden="true"></i></span>
              <span>{{subcontent.title}}</span>
            </a>
          </li>
        </ul>
      </div>
      <div>
        <section [ngClass]="{ 'show': selectedSubTitle == subcontent.title }" class="ml-2 mr-2 tab-content hide" *ngFor="let subcontent of content.subcontents">
          <div *ngIf="loadSubcontentTemplate">
            <ng-container [ngTemplateOutlet]="subcontentTemplate"
                          [ngTemplateOutletContext]="{subcontent:subcontent}"></ng-container>
          </div>
          <div *ngIf="!loadSubcontentTemplate">
            <div [innerHTML]="subcontent.description | safeHtml">
            </div>
          </div>
        </section>
      </div>
    </section>
  </div>
</div>
