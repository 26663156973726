import { Routes } from '@angular/router'
import {
  NotFoundComponent
} from './shared.index';

export const SharedRoutes: Routes = [
  {
    path: 'notfound',
    component: NotFoundComponent
  }
];

