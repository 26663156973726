<mat-accordion multi>
  <mat-expansion-panel *ngFor="let content of contents; let i = index" [expanded]="firstLevel === content.content_uid || firstLevel === content.title"
                       (opened)="setFirstStep(content)" (closed)="closeFirstStep(content)">
    <mat-expansion-panel-header>
      <mat-panel-title class="has-text-weight-semibold title is-6">
        <div *ngIf="loadContentTitleTemplate">
          <ng-container [ngTemplateOutlet]="contentTitleTemplate"
                        [ngTemplateOutletContext]="{content:content}"></ng-container>
        </div>
        <div *ngIf="!loadContentTitleTemplate" [style.color]="content.title_color ? content.title_color.code : ''">
          {{content.title}}
        </div>
      </mat-panel-title>
      <!--<mat-panel-description>
        This is a summary of the content
      </mat-panel-description>-->
    </mat-expansion-panel-header>

    <div *ngIf="loadContentTemplate">
      <ng-container [ngTemplateOutlet]="contentTemplate"
                    [ngTemplateOutletContext]="{content:content}"></ng-container>
    </div>

    <div *ngIf="!loadContentTemplate">
      <div [innerHTML]="content.description | safeHtml">
      </div>
    </div>

    <div *ngIf="content.subcontents.length && loadSubcontentPanel" class="pt-4">
      <mat-accordion multi>
        <ng-container *ngFor="let subcontent of content.subcontents; let i = index">
          <mat-expansion-panel *ngIf="subcontent.is_active" [expanded]="secondLevel === subcontent.content_uid || secondLevel === subcontent.title"
                               (opened)="setSecondStep(subcontent)" (closed)="closeSecondStep(subcontent)">
            <mat-expansion-panel-header>
              <mat-panel-title class="has-text-weight-semibold subtitle is-6" [style.color]="subcontent.title_color ? subcontent.title_color.code : ''">
                {{subcontent.title}}
              </mat-panel-title>
              <!--<mat-panel-description>
                This is a summary of the content
              </mat-panel-description>-->
            </mat-expansion-panel-header>
            <div *ngIf="loadSubcontentTemplate">
              <ng-container [ngTemplateOutlet]="subcontentTemplate"
                            [ngTemplateOutletContext]="{
                              subcontent:subcontent,
                              firstlevel:content.content_uid ? content.content_uid : content.title,
                              secondlevel:subcontent.content_uid ? subcontent.content_uid : subcontent.title,
                              thirdlevel:thirdLevel
                            }"></ng-container>
            </div>
            <div *ngIf="!loadSubcontentTemplate">
              <div [innerHTML]="subcontent.description | safeHtml">
              </div>
            </div>
          </mat-expansion-panel>
        </ng-container>
      </mat-accordion>
    </div>
  </mat-expansion-panel>
</mat-accordion>
