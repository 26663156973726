import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";

import SharedService from '../../shared/shared.service';
import { LEGAL_DISCLAIMER, LEGAL_DISCLAIMER_TITLE } from '../../shared/constants/pages.constants';
import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'legal-disclaimer',
  templateUrl: './legal-disclaimer.component.html',
  styleUrls: []
})
export class LegalDisclaimerComponent extends BaseComponent {

  constructor(sharedService: SharedService
    , private titleService: Title,
    route: ActivatedRoute, router: Router) {
    super(sharedService, route, router);
    this.uid = LEGAL_DISCLAIMER;
    this.titleService.setTitle(LEGAL_DISCLAIMER_TITLE);
  }

  selectTab(tabName: string) {
    this.selectedTitle = tabName;
  }
}
