import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";

import SharedService from '../../shared/shared.service';
import { SERVICING_INDIVIDUAL_LABS } from '../../shared/constants/pages.constants';
import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-servicing-individual-labs',
  templateUrl: './servicing-individual-labs.component.html',
  styleUrls: ['./servicing-individual-labs.component.css']
})
export class ServicingIndividualLabsComponent extends BaseComponent {

  constructor(sharedService: SharedService
    , private titleService: Title,
    route: ActivatedRoute, router: Router) {
    super(sharedService, route, router);
    this.uid = SERVICING_INDIVIDUAL_LABS;
    this.titleService.setTitle("Servicing Individual Labs");
  }

  selectTab(tabName: string) {
    this.selectedTitle = tabName;
  }
}
