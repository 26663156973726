

<section class="content section">
  <div class="container" *ngIf="page">
    <h3 class="title">
      {{page.title}}
    </h3>
    <div class="m-b-sm m-t-sm" *ngIf="page.notification">
      <div class="ql-editor notification" [innerHTML]="page.notification | safeHtml">
      </div>
    </div>
    <div class="m-b-sm m-t-sm" *ngIf="page.description">
      <div class="editor ql-editor" [innerHTML]="page.description | safeHtml">
      </div>
    </div>

    <div class="m-b-sm m-t-sm" *ngIf="page.content_display_type && page.content_display_type.display_type_uid == contentDisplayType.ACCORDION">
      <accordion [contents]="contents" [loadContentTemplate]="false"
                 [loadContentTitleTemplate]="false"></accordion>
    </div>

    <div class="m-b-sm m-t-sm" *ngIf="page.content_display_type && page.content_display_type.display_type_uid == contentDisplayType.TABS">
      <tabs [contents]="contents" [selectedTitle]="selectedTitle" [selectedSubTitle]="selectedSubTitle" [loadContentTemplate]="false"></tabs>
    </div>

    <div class="m-b-sm m-t-sm" *ngIf="page.content_display_type && page.content_display_type.display_type_uid == contentDisplayType.LIST">
      <list [contents]="contents" [loadContentTemplate]="false"></list>
    </div>

  </div>
</section>
 news events page

<section class="content section">
  <div class="container" *ngIf="page">
    <h3 class="title">
      {{page.title}}
    </h3>
    <div class="columns">
      <div class="column is-8">
        <accordion [contents]="contents" [titleHeight]="70"
                   [loadContentTitleTemplate]="true" [contentTitleTemplate]="contentTitleTemplate"
                   [loadContentTemplate]="true" [contentTemplate]="contentTemplate" [firstLevel]="firstLevel" [secondLevel]="secondLevel" [thirdLevel]="thirdLevel"></accordion>
      </div>
      <div class="column">
        <div class="box">
          <iframe src="twitter" style="width:100%; min-height:500px"></iframe>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #contentTitleTemplate let-content="content">
  <div>
    <p [style.color]="content.title_color ? content.title_color.code : ''">
      {{content.title}}
    </p>
    <p style="color:rgb(255, 153, 0)" class="is-size-7">
      {{content.datetime | date:'EE, MMM d, yyyy'}}
      <ng-container *ngIf="content.sub_title">
        @ {{content.sub_title}}
      </ng-container>
    </p>
  </div>
</ng-template>

<ng-template #contentTemplate let-content="content">
  <div class="m-b-sm m-t-sm" *ngIf="content.description">
    <div [innerHTML]="content.description | safeHtml">
    </div>
  </div>
  <div class="m-b-sm m-t-sm" *ngIf="content.images.length">
    <!--- <img [src]="_cmsApiUrl + getImageURL(content)" [alt]="content.title"> --->
    <img [src]="getImageURL(content)" [alt]="content.title">
  </div>
</ng-template>
