import { OnInit, Directive } from '@angular/core';

import SharedService from '../shared/shared.service';
import { IPage, IContent, IPageObject, INav, IContentObject, ISubcontent, IColor, IImage } from '../shared/model/model.index';
import * as ContentDisplayTypes from '../shared/constants/content-display-type.constants';

import { ActivatedRoute, Router } from '@angular/router';

import { environment } from '../../environments/environment';
import { IContentDisplayType } from '../shared/model/IContentDisplayType';

@Directive()
export abstract class BaseComponent implements OnInit {
  page: IPage;
  contents: IContent[] = <IContent[]>[];
  uid: number;
  selectedTitle: string;
  selectedSubTitle: string;
  isFullContent: boolean;
  _cmsApiUrl: string;
  contentDisplayType: any = ContentDisplayTypes;

  firstLevel: string;
  secondLevel: string;
  thirdLevel: string;

  constructor(
    private sharedService: SharedService,
    private route: ActivatedRoute,
    private router: Router) {
    this.page = null;
    this._cmsApiUrl = environment.cmsApiBaseUrl;

    this.route.params.subscribe(params => {
      this.firstLevel = params['firstlevel'] ? params['firstlevel'].toString() : undefined;
      this.secondLevel = params['secondlevel'] ? params['secondlevel'].toString() : undefined;
      this.thirdLevel = params['thirdlevel'] ? params['thirdlevel'].toString() : undefined;
    });
  }

  ngOnInit() {
    this.getPage();
    this.getContent("order");
  }

  getPage() {
    this.sharedService.getPageByUID(this.uid).subscribe((p: IPageObject) => {
      //if (p.nav && p.nav.is_disabled) {
      //  this.router.navigateByUrl("/notfound");
      //}

      //if(p.data.attributes.nav && p.data.attributes....): nav IS MISSING

      if (p) {

        const attr = p.data[0].attributes;
        const navP: INav = {
          created_at: '', published_at: "", updated_at: "",
          id: 9,// attr.nav.data[0].id,
          is_disabled: false,
          is_external: true, is_parent: false,
          name: "nav",// p.data.attributes.nav.data[0].attributes.name,
          sub_navs: null,
          url: "url"// p.data.attributes.nav.data[0].attributes.url
        }

        const cdt: IContentDisplayType = {

          display_type: attr.content_display_type.data ? attr.content_display_type.data.attributes.display_type : null,
          display_type_uid: attr.content_display_type.data ? attr.content_display_type.data.attributes.display_type_uid : 0,
          id: attr.content_display_type.data ? attr.content_display_type.data.id : 0,
          created_at: '', published_at: '', updated_at: ''
        }


        const thisPage: IPage = {
          id: p.data[0]?.id,
          content_display_type: cdt,
          created_at: attr.createdAt, published_at: attr.publishedAt, updated_at: attr.updatedAt,
          title: attr.title,
          description: attr.description,
          notification: attr.notification,
          is_active: attr.is_active,
          uid: attr.uid,
          nav: navP,
          url: attr.url
        }

        this.page = thisPage;
        //this.page = p;
      }
    });
  }

  getContent(sort: string = null) {
    /*this.sharedService.getPageContentByUID(this.uid, sort).subscribe(c => {*/
    this.sharedService.getPageContentByID(this.uid, sort).subscribe(c => {

      const cdt: IContentDisplayType = { created_at: "", display_type: "", display_type_uid: 1, id: 0, published_at: "", updated_at: "" }
      const items: IContentObject = c;
      const data = items.data.map(x => {

        const iimg: IImage = {
          alternativeText: x.attributes.images.data ? x.attributes.images.data[0].attributes.alternativeText : "",
          caption: x.attributes.images.data ? x.attributes.images.data[0].attributes.caption : "",
          ext: x.attributes.images.data ? x.attributes.images.data[0].attributes.ext : "",
          formats: x.attributes.images.data ? x.attributes.images.data[0].attributes.formats : null,
          hash: "",
          height: x.attributes.images.data ? x.attributes.images.data[0].attributes.height : 0,
          mime: x.attributes.images.data ? x.attributes.images.data[0].attributes.mime : "",
          name: "", previewUrl: "", provider: "", provider_metadata: "",
          size: x.attributes.images.data ? x.attributes.images.data[0].attributes.size : 0,
          width: x.attributes.images.data ? x.attributes.images.data[0].attributes.width : 0,
          url: x.attributes.images.data ? x.attributes.images.data[0].attributes.url.replace('/uploads', '/../uploads').replace('thumbnail_', '') : "",
          //url: x.attributes.images.data[0].attributes.formats.small.url.replace('/uploads', '/../uploads').replace('small_', ''),
          created_at: "", updated_at: "",
          id: x.attributes.images.data ? x.attributes.images.data[0].id : 0
        }

        //iimg.formats.thumbnail.url = iimg.formats.thumbnail.url.replace("uploads", "../uploads");
        //iimg.formats.small.url = iimg.formats.small.url.replace("uploads", "../uploads");
        let imgs: IImage[] = [iimg];

        //const ipage: IPage = {
        //  id: 1,// x.attributes.page.data.attributes.uid,
        //  title: x.attributes.page?.data.attributes.title,
        //  description: x.attributes.page?.data.attributes.description,
        //  uid: x.attributes.page?.data.attributes.uid,
        //  content_display_type:  cdt,
        //  nav: null
        //  is_active: x.attributes.page?.data.attributes.is_active,
        //  notification: x.attributes.page?.data.attributes.notification,
        //  created_at: "", published_at: "", updated_at: "",
        //}

        const icolor: IColor = { code: "", created_at: "", id: 0, name: "BLUE", published_at: "", updated_at: "" }

        let subcontents: IContent[] = [];

        //const content: IContent = {
        //  id: x?.id,
        //  title: x.attributes.title,
        //  sub_title: x.attributes.sub_title,
        //  url: x.attributes.url,
        //  description: x.attributes.description,
        //  is_active: x.attributes.is_active,
        //  order: x.attributes.order,
        //  page: null, //  ipage,
        //  images: imgs,
        //  subcontents: [],   //SubContents,
        //  title_color: icolor,
        //  datetime: "",
        //  notification: x.attributes.notification,
        //  content_uid: x.attributes.content_uid,
        //  published_at: "",
        //  created_at: "",
        //  updated_at: ""
        //};

        //let contents: IContent[] = [content];

        let SubContents: ISubcontent[] = [];

        x.attributes.subcontents.data.forEach(c => {

          let SubSubContents: ISubcontent[] = [];

          let contentImg: IImage;

          if (c.attributes.images && c.attributes.images.data) {
            contentImg = {
              alternativeText: c.attributes.images.data ? x.attributes.images.data[0].attributes.alternativeText : "",
              caption: c.attributes.images.data ? c.attributes.images.data[0].attributes.caption : "",
              ext: c.attributes.images.data ? c.attributes.images.data[0].attributes.ext : "",
              formats: c.attributes.images.data ? c.attributes.images.data[0].attributes.formats : null,
              hash: "",
              height: c.attributes.images.data ? c.attributes.images.data[0].attributes.height : 0,
              mime: c.attributes.images.data ? c.attributes.images.data[0].attributes.mime : "",
              name: "", previewUrl: "", provider: "", provider_metadata: "",
              size: c.attributes.images.data ? x.attributes.images.data[0].attributes.size : 0,
              width: c.attributes.images.data ? x.attributes.images.data[0].attributes.width : 0,
              url: c.attributes.images.data ? x.attributes.images.data[0].attributes.url.replace('/uploads', '/../uploads').replace('thumbnail_', '') : "",
              //url: x.attributes.images.data[0].attributes.formats.small.url.replace('/uploads', '/../uploads').replace('small_', ''),
              created_at: "", updated_at: "",
              id: x.attributes.images.data ? x.attributes.images.data[0].id : 0
            };
          }


          c.attributes.subcontents.data.forEach(ss => {
            const subsub: ISubcontent = {
              content_uid: ss.attributes.content_uid,
              created_at: "", datetime: "", published_at: "", updated_at: "",
              description: ss.attributes.description,
              id: -9,
              is_active: ss.attributes.is_active,
              order: ss.attributes.order,
              subcontents: [],
              sub_title: ss.attributes.sub_title,
              image: contentImg,
              title: ss.attributes.title,
              title_color: null,
              url: ss.attributes.url
            }

            SubSubContents.push(subsub);
          });


          const subcontent: ISubcontent = {
            content_uid: c.attributes.content_uid,
            created_at: "", datetime: "", published_at: "", updated_at: "",
            description: c.attributes.description,
            id: -9,
            is_active: c.attributes.is_active,
            order: c.attributes.order,
            subcontents: SubSubContents,
            sub_title: c.attributes.sub_title,
            image: contentImg,
            title: c.attributes.title,
            title_color: null,
            url: c.attributes.url
          }
          SubContents.push(subcontent);
        }); //END OF SUBCONTENTS



        const ic: IContent = {
          id: x?.id,
          title: x.attributes.title,
          sub_title: x.attributes.sub_title,
          url: x.attributes.url,
          description: x.attributes.description,
          is_active: x.attributes.is_active,
          order: x.attributes.order,
          page: null, //  ipage,
          images: imgs,
          subcontents: SubContents, //[]
          title_color: icolor,
          datetime: "",
          notification: x.attributes.notification,
          content_uid: x.attributes.content_uid,
          published_at: "",
          created_at: "",
          updated_at: ""
        };

        return ic;
      });

      this.contents = data;


      if (this.contents.length > 0) {
        this.selectedTitle = this.contents[0].title;
        if (this.firstLevel) {
          this.selectedTitle = this.firstLevel;
        }
        if (this.contents[0].subcontents.length > 0) {
          this.selectedSubTitle = this.contents[0].subcontents[0].title;
          if (this.secondLevel) {
            this.selectedSubTitle = this.secondLevel;
          }
        }
      }

    });
  }


  getImageURL(content: IContent): string {

    if (content.images[0].formats) {
      if (content.images[0].formats.large) {
        return content.images[0].formats.large.url.replace('large_', '').replace('/uploads', '/../uploads');
      }
      else if (!content.images[0].formats.large && content.images[0].formats.medium) {
        return content.images[0].formats.medium.url.replace('medium_', '').replace('/uploads', '/../uploads');
      }
      else if (!content.images[0].formats.large && !content.images[0].formats.medium && content.images[0].formats.small) {
        return content.images[0].formats.small.url.replace('small_', '').replace('/uploads', '/../uploads');
      }
      else {
        return content.images[0].formats.thumbnail.url.replace('thumbnail_', '').replace('/uploads', '/../uploads');
      }

    }
    else { return ""; }
  }

  //SantizeURL(url): string {
  //  return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  //}
}
