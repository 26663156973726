import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";

import SharedService from '../../shared/shared.service';
import { TRAINING_CLASSESS } from '../../shared/constants/pages.constants';

import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-training-classes',
  templateUrl: './training-classes.component.html',
  styleUrls: ['./training-classes.component.css']
})
export class TrainingClassesComponent extends BaseComponent {
  constructor(sharedService: SharedService
    , private titleService: Title
    , route: ActivatedRoute
    , router: Router
  ) {
    super(sharedService, route, router);
    this.uid = TRAINING_CLASSESS;
    this.titleService.setTitle('Classes');
  }

  selectTab(tabName: string) {
    this.selectedTitle = tabName;

  }
}
