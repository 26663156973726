import { Directive, Input, TemplateRef } from '@angular/core';
import { IContent } from '../model/model.index';

import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Directive()
export abstract class BaseComponent {
  @Input() public contents: IContent[];
  @Input() public titleHeight: string = "";
  @Input() public loadContentTitleTemplate: boolean;
  @Input() public contentTitleTemplate: TemplateRef<any>;

  @Input() public loadContentTemplate: boolean;
  @Input() public contentTemplate: TemplateRef<any>;

  @Input() public loadSubcontentPanel: boolean = true;
  @Input() public loadSubcontentTemplate: boolean;
  @Input() public subcontentTemplate: TemplateRef<any>;

  @Input() public firstLevel: string;
  @Input() public secondLevel: string;
  @Input() public thirdLevel: string;

  routePath: string;
  location: Location;

  constructor(
    private route: ActivatedRoute,
    _location: Location) {

    this.location = _location;
    this.route.url.subscribe(urlSegments => {
      this.routePath = urlSegments.length ? urlSegments[0].path.toLowerCase() : '';
    });
  }
}
