import { Component, OnInit } from '@angular/core';
import { Title } from "@angular/platform-browser";

import { BaseComponent } from '../base.component';
import SharedService from '../../shared/shared.service';
import { SEARCH, SEARCH_TITLE } from '../../shared/constants/pages.constants';
import { ActivatedRoute, Router } from '@angular/router';
import { IContent, IContentObject, ISubcontent, IColor, IImage, IPage } from '../../shared/model/model.index';

@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrls: []
})
export class SearchComponent extends BaseComponent implements OnInit {
  searchText: string ='';
  private _sharedService: SharedService;
  private _route: ActivatedRoute;
  pages: IPage[] = <IPage[]>[];


  constructor(sharedService: SharedService,
    private titleService: Title,
    route: ActivatedRoute,
    router: Router) {
    super(sharedService, route, router);
    this.uid = SEARCH;
    this._sharedService = sharedService;
    this._route = route;
    this.titleService.setTitle(SEARCH_TITLE);
  }

  ngOnInit() {
    this.getPage();

    this.getContent();

    this._route.params.subscribe(params => {
      this.searchText = params['searchText'].trim();

      this._sharedService.getSearchContent(this.searchText).subscribe(c => {

        const items: IContentObject = c;
        const iimg: IImage = null;

        const data = items.data.map(x => {

          const _page = x.attributes.page;
          if (_page.data) {


            let imgs: IImage[] = [iimg];
            const icolor: IColor = { code: "", created_at: "", id: 0, name: "BLUE", published_at: "", updated_at: "" }
            let SubContents: ISubcontent[] = [];


            x.attributes.subcontents.data.forEach(ss => {
              const subsub: ISubcontent = {
                content_uid: ss.attributes.content_uid,
                created_at: "", datetime: "", published_at: "", updated_at: "",
                description: ss.attributes.description,
                id: -9,
                is_active: ss.attributes.is_active,
                order: ss.attributes.order,
                subcontents: [],
                sub_title: ss.attributes.sub_title,
                image: null, //contentImg,
                title: ss.attributes.title,
                title_color: null,
                url: ss.attributes.url
              }

              SubContents.push(subsub);
            });


            let page: IPage = {
              uid: x.attributes.page.data.attributes.uid,
              //content_display_type: x.attributes.page.data.attributes.content_display_type,
              title: x.attributes.page.data.attributes.title,
              description: x.attributes.page.data.attributes.description,
              nav: null, //navP,
              content_display_type: null,
              notification: x.attributes.page.data.attributes.notification,
              id: x.attributes.page.data.attributes.uid,
              created_at: x.attributes.page.data.attributes.createdAt,
              published_at: x.attributes.page.data.attributes.publishedAt,
              updated_at: x.attributes.page.data.attributes.updatedAt,
              is_active: true,
              url: x.attributes.page.data.attributes.url   //navP.url

            };

            const ic: IContent = {
              id: x?.id,
              title: x.attributes.title,
              sub_title: x.attributes.sub_title,
              url: x.attributes.page.data.attributes.title.toLowerCase().replace(" ", "-").split(' ').join('-'), // x.attributes.page.data.attributes.url, 
              description: x.attributes.description,
              is_active: x.attributes.is_active,
              order: x.attributes.order,
              page: page, //  ipage,
              images: imgs,
              subcontents: SubContents, //[]
              title_color: icolor,
              datetime: "",
              notification: x.attributes.notification,
              content_uid: x.attributes.content_uid,
              published_at: "",
              created_at: "",
              updated_at: ""
            };


            return ic;

          }
        });

        this.contents = data;


      });


      this._sharedService.getSearchPagesByDescription(this.searchText.trim()).subscribe(c => {
        if (c) {

          const pageData: IPage[] = [];

          c.data.forEach(p => {

            const pa: IPage = {
              content_display_type: null, // p.attributes.content_display_type,
              description: p.attributes.description,
              id: p.attributes.uid,
              is_active: p.attributes.is_active,
              nav:null,// nav,
              notification: p.attributes.notification,
              title: p.attributes.title,
              uid: p.attributes.uid,
              url: p.attributes.nav.data ? p.attributes.nav.data.attributes.url : null,
              updated_at: p.attributes.updatedAt,
              published_at:p.attributes.publishedAt,
              created_at: p.attributes.createdAt
            }
            pageData.push(pa);
          });

          this.pages = pageData;

        }

      });

    });
  }
}
