import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";

import SharedService from '../../shared/shared.service';
import { CORE_FACILITY_RELATED, CORE_FACILITY_RELATED_TITLE } from '../../shared/constants/pages.constants';
import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-core-facility-related',
  templateUrl: './core-facility-related.component.html',
  styleUrls: ['./core-facility-related.component.css']
})
export class CoreFacilityRelatedComponent extends BaseComponent {

  constructor(sharedService: SharedService
    , private titleService: Title,
    route: ActivatedRoute, router: Router) {
    super(sharedService, route, router);
    this.uid = CORE_FACILITY_RELATED;
    this.titleService.setTitle(CORE_FACILITY_RELATED_TITLE);
  }

  selectTab(tabName: string) {
    this.selectedTitle = tabName;
  }
}



