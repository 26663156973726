import { Component } from '@angular/core';
import {
  IconDefinition,
  faFacebookSquare,
  faTwitterSquare,
  faInstagramSquare,
  faYoutubeSquare,
  faLinkedin
} from '@fortawesome/free-brands-svg-icons';

import {
  
} from '@fortawesome/free-regular-svg-icons';

import {
  faPhoneAlt,
  faMapMarkerAlt
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  faFacebook: IconDefinition = faFacebookSquare;
  faTwitter: IconDefinition = faTwitterSquare;
  faInstagram: IconDefinition = faInstagramSquare;
  faYoutube: IconDefinition = faYoutubeSquare;
  faLinkedin: IconDefinition = faLinkedin;
  faPhone: IconDefinition = faPhoneAlt;
  faMapMarker: IconDefinition = faMapMarkerAlt;
}
