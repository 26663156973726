import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";

import { BaseComponent } from '../base.component';
import SharedService from '../../shared/shared.service';
import { PANEL_DESIGN, PANEL_DESIGN_TITLE } from '../../shared/constants/pages.constants';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'panel-design',
  templateUrl: './panel-design.component.html',
  styleUrls: []
})
export class PanelDesignComponent extends BaseComponent {
  constructor(sharedService: SharedService
    , private titleService: Title,
    route: ActivatedRoute, router: Router) {
    super(sharedService, route, router);
    this.uid = PANEL_DESIGN;
    this.titleService.setTitle(PANEL_DESIGN_TITLE);
  }
}
