<section class="content section">
  <div class="container" *ngIf="page">
    <h3 class="page-title">
      {{page.title}}
    </h3>
    <div class="m-b-sm m-t-sm" *ngIf="page.notification">
      <div class="ql-editor notification" [innerHTML]="page.notification | safeHtml">
      </div>
    </div>
    <div class="m-b-sm m-t-sm" *ngIf="page.description">
      <div class="editor ql-editor" [innerHTML]="page.description | safeHtml">
      </div>
    </div>
    <div class="m-b-sm m-t-sm">
      <div *ngIf="contents.length > 0">
        <div *ngFor="let content of contents" [id]="content.content_uid">
          <h2 [style.color]="content.title_color ? content.title_color.code : ''">{{content.title}}</h2>
          <h4 style="color: #ff9900;">{{content.sub_title}}</h4>
          <div [innerHTML]="content.description | safeHtml"></div>
        </div>
      </div>
    </div>
  </div>
</section>
