<!--https://gsr.dev/material2-carousel/-->
<!--https://github.com/gbrlsnchs/material2-carousel-->
<div class="wrapper">
  <div class="columns mb-0">
    <div class="column is-full p-0">
      <mat-carousel timings="250ms ease-in"
                    [autoplay]="true"
                    interval="6000"
                    color="accent"
                    maxWidth="auto"
                    slides="5"
                    [loop]="true"
                    [hideArrows]="false"
                    [hideIndicators]="true"
                    [useKeyboard]="true"
                    [useMouseWheel]="false"
                    [maintainAspectRatio]="true"
                    [proportion]="29"
                    orientation="ltr">
        <mat-carousel-slide #matCarouselSlide
                            *ngFor="let slide of sliders; let i = index"
                            overlayColor="#00000040"
                            [hideOverlay]="true">
          <a [attr.href]="slide.url" target="_blank" *ngIf="slide.is_external && slide.url">
            <!--- <img [src]="_cmsApiUrl + slide.image.url"> --->
            <img [src]="slide.image.url">
          </a>
          <a [routerLink]="slide.url" *ngIf="!slide.is_external">
            <!--- <img [src]="_cmsApiUrl + slide.image.url"> --->
            <img [src]="slide.image.url">
          </a>
        </mat-carousel-slide>
      </mat-carousel>
    </div>
  </div>


  <div class="columns m-2">
    <div class="column is-8" *ngIf="mission">
      <div class="box mb-0" style="height:100%">
        <!--<h3 class="title is-4">
          {{mission.title}}
        </h3>-->
        <div [innerHTML]="mission.description | safeHtml">
        </div>
      </div>
    </div>
    <div class="column" *ngIf="ackFacility">
      <div class="box" style="height:100%">
        <!--<h3 class="title is-4">
          {{ackFacility.title}}
        </h3>-->
        <div [innerHTML]="ackFacility.description | safeHtml">
        </div>
      </div>
    </div>
  </div>


  <!--<div class="columns m-2">
    <div class="column is-8" *ngIf="newsevents" style="padding-top:0!important; ">
      <div class="box mb-0" style="height:100%">
        <h3 class="title is-4">
          {{newsevents.title}}
        </h3>
        <div [innerHTML]="newsevents.description | safeHtml" style="padding-left: 15%">
        </div>
      </div>
    </div>


    <div class="column">
      <div class="box" style="height:100%">
        <iframe src="twitter" style="width:100%; min-height:300px"></iframe>
      </div>
    </div>


  </div>-->
</div>

<ng-template #contentTitleTemplate let-content="content">
  <div>
    <p [style.color]="content.title_color ? content.title_color.code : ''">
      {{content.title}}
    </p>
    <p style="color:rgb(255, 153, 0)" class="title is-7">
      {{content.datetime | date:'EE, MMM d, yyyy'}}
      <ng-container *ngIf="content.sub_title">
        @ {{content.sub_title}}
      </ng-container>
    </p>
  </div>
</ng-template>

<ng-template #contentTemplate let-content="content">
  <div class="m-b-sm m-t-sm" *ngIf="content.description">
    <div [innerHTML]="content.description | safeHtml">
    </div>
  </div>
  <div class="m-b-sm m-t-sm" *ngIf="content.images.length">
    <img [src]="_cmsApiUrl + getImageURL(content)" [alt]="content.title">
  </div>
</ng-template>
