import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";

import SharedService from '../../shared/shared.service';
import { ANALYSIS_SOFTWARE } from '../../shared/constants/pages.constants';
import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-analysis-software',
  templateUrl: './analysis-software.component.html',
  styleUrls: ['./analysis-software.component.css']
})
export class AnalysisSoftwareComponent extends BaseComponent {

  constructor(sharedService: SharedService
    , private titleService: Title,
    route: ActivatedRoute, router: Router) {
    super(sharedService, route, router);
    this.uid = ANALYSIS_SOFTWARE;
    this.titleService.setTitle("Analysis Software");
  }

  selectTab(tabName: string) {
    this.selectedTitle = tabName;
  }
}
