import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";

import SharedService from '../../shared/shared.service';
import { TRAINING_EXCYTE_MASTERY, TRAINING_ON_ANALYZERS } from '../../shared/constants/pages.constants';

import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-training-excyte-mastery',
  templateUrl: './training-excyte-mastery.component.html',
  styleUrls: ['./training-excyte-mastery.component.css']
})
export class TrainingExcyteMasteryComponent extends BaseComponent {
  constructor(sharedService: SharedService
    , private titleService: Title
    , route: ActivatedRoute
    , router: Router
  ) {
    super(sharedService, route, router);
    this.uid = TRAINING_EXCYTE_MASTERY;
    this.titleService.setTitle('Classes');
  }

  selectTab(tabName: string) {
    this.selectedTitle = tabName;

  }
}
