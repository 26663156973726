import { Component } from '@angular/core';

@Component({
  selector: 'not-found',
  templateUrl: './not-found.component.html',
  styleUrls: []
})
export class NotFoundComponent {

}
