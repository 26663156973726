import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";

import { BaseComponent } from '../base.component';
import SharedService from '../../shared/shared.service';
import { ONLINE_TOOLS, ONLINE_TOOLS_TITLE } from '../../shared/constants/pages.constants';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'online-tools',
  templateUrl: './online-tools.component.html',
  styleUrls: []
})
export class OnlineToolsComponent extends BaseComponent {
  constructor(sharedService: SharedService
    , private titleService: Title,
    route: ActivatedRoute, router: Router) {
    super(sharedService, route, router);
    this.uid = ONLINE_TOOLS;
    this.titleService.setTitle(ONLINE_TOOLS_TITLE);
  }
}
