import { Content } from '@angular/compiler/src/render3/r3_ast';
import { Component } from '@angular/core';
import { IContent } from '../../model/IContent';
import { ISubcontent } from '../../model/ISubcontent';
import { BaseComponent } from '../base.component';

import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'accordion',
  templateUrl: './accordion.component.html',
  styleUrls: []
})
export class AccordionComponent extends BaseComponent {

  constructor(route: ActivatedRoute,
    location: Location) {
    super(route, location);
  }

  setFirstStep(content: IContent) {
    this.firstLevel = content.content_uid ? content.content_uid : content.title;

    this.location.replaceState(`/${this.routePath}/${this.firstLevel}`);
  }

  closeFirstStep(content: IContent) {
    const stepValue = content.content_uid ? content.content_uid : content.title;
    if (stepValue !== this.firstLevel) {
      this.location.replaceState(`/${this.routePath}/${this.firstLevel}`);
    }
    else {
      this.location.replaceState(`/${this.routePath}`);
    }
  }

  setSecondStep(subcontent: ISubcontent) {
    this.secondLevel = subcontent.content_uid ? subcontent.content_uid : subcontent.title;
    if (subcontent.subcontents.length) {
      let content = this.thirdLevel && subcontent.subcontents.find(s => s.title == this.thirdLevel);

      if (!content)
        content = this.thirdLevel && subcontent.subcontents.find(s => s.content_uid == this.thirdLevel);

      if (!content) {
        this.thirdLevel = subcontent.subcontents[0].content_uid ? subcontent.subcontents[0].content_uid : subcontent.subcontents[0].title;
      }
    }

    if (this.secondLevel && this.thirdLevel)
      this.location.replaceState(`/${this.routePath}/${this.firstLevel}/${this.secondLevel}/${this.thirdLevel}`);

    else if (this.secondLevel && !this.thirdLevel)
      this.location.replaceState(`/${this.routePath}/${this.firstLevel}/${this.secondLevel}`);
  }

  closeSecondStep(subcontent: ISubcontent) {
    const stepValue = subcontent.content_uid ? subcontent.content_uid : subcontent.title;
    if (stepValue !== this.secondLevel) {
      this.location.replaceState(`/${this.routePath}/${this.firstLevel}/${this.secondLevel}/${this.thirdLevel}`);
    }
    else {
      this.location.replaceState(`/${this.routePath}/${this.firstLevel}`);
    }
  }
}
