<section class="content">
  <section class="section" *ngIf="page">
    <div class="container">
      <h3 class="page-title">
        {{page.title}}
      </h3>
      <div class="m-b-sm m-t-sm" *ngIf="page.notification">
        <div class="ql-editor notification" [innerHTML]="page.notification | safeHtml">
        </div>
      </div>
      <div class="m-b-sm m-t-sm" *ngIf="page.description">
        <div class="editor ql-editor" [innerHTML]="page.description | safeHtml">
        </div>
      </div>

      <div class="m-b-sm m-t-sm" *ngIf="page.content_display_type && page.content_display_type.display_type_uid == contentDisplayType.ACCORDION">
        <accordion [contents]="contents" [contentTemplate]="contentTemplate" [loadContentTemplate]="true"></accordion>
      </div>

      <div class="m-b-sm m-t-sm" *ngIf="page.content_display_type && page.content_display_type.display_type_uid == contentDisplayType.TABS">
        <tabs [contents]="contents" [selectedTitle]="selectedTitle" [contentTemplate]="contentTemplate" [loadContentTemplate]="true"></tabs>
      </div>

      <div class="m-b-sm m-t-sm" *ngIf="page.content_display_type && page.content_display_type.display_type_uid == contentDisplayType.LIST">
        <list [contents]="contents" [contentTemplate]="contentTemplate" [loadContentTemplate]="true"></list>
      </div>

    </div>
  </section>
</section>

<ng-template #contentTemplate let-content="content">
  <div class="columns">
    <div class="column is-5">
      <div class="box" style="height:100%">
        <iframe [src]="sanitizer.bypassSecurityTrustResourceUrl(content.url)" frameborder="0" style="border:0; width:100%" allowfullscreen=""></iframe>
      </div>
    </div>
    <div class="column">
      <div class="box" style="height:100%">
        <p class="title is-5"><span [style.color]="content.title_color ? content.title_color.code : ''">{{content.title}}</span></p>
        <div [innerHTML]="content.description | safeHtml"></div>
      </div>
    </div>
  </div>
</ng-template>
