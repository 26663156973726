import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";

import { BaseComponent } from '../base.component';
import SharedService from '../../shared/shared.service';
import { SORT_CALCULATOR_TITLE, SORT_CALCULATOR } from '../../shared/constants/pages.constants';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'sort-calculator',
  templateUrl: './sort-calculator.component.html',
  styleUrls: []
})


//export class SortCalculatorComponent {
//  constructor(private titleService: Title) {
//    this.titleService.setTitle(SORT_CALCULATOR_TITLE);
//  }
//}

export class SortCalculatorComponent extends BaseComponent {
  constructor(sharedService: SharedService
    , private titleService: Title,
    route: ActivatedRoute, router: Router) {
    super(sharedService, route, router);
    this.uid = SORT_CALCULATOR;
    this.titleService.setTitle(SORT_CALCULATOR_TITLE);
  }
}
