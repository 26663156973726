import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";

import { BaseComponent } from '../base.component';
import SharedService from '../../shared/shared.service';
import { PROTOCOLS_TITLE, PROTOCOLS } from '../../shared/constants/pages.constants';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'protocols',
  templateUrl: './protocols.component.html',
  styleUrls: []
})
export class ProtocolsComponent extends BaseComponent {
  constructor(sharedService: SharedService
    , private titleService: Title,
    route: ActivatedRoute, router: Router) {
    super(sharedService, route, router);
    this.uid = PROTOCOLS;
    this.titleService.setTitle(PROTOCOLS_TITLE);
  }
}
