import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";

import SharedService from '../../shared/shared.service';
import { CANCER_CENTER_SUPPORT, CANCER_CENTER_SUPPORT_TITLE } from '../../shared/constants/pages.constants';
import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'cancer-center-support',
  templateUrl: './cancer-center-support.component.html',
  styleUrls: []
})
export class CancerCenterSupportComponent extends BaseComponent {
  constructor(sharedService: SharedService
    , private titleService: Title,
    route: ActivatedRoute, router: Router) {
    super(sharedService, route, router);
    this.uid = CANCER_CENTER_SUPPORT;
    this.titleService.setTitle(CANCER_CENTER_SUPPORT_TITLE);
  }
}

