import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";

import SharedService from '../../shared/shared.service';
import { TECHNOLOGY, TECHNOLOGY_TITLE } from '../../shared/constants/pages.constants';
import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';

import {
  IconDefinition,
  faPlayCircle
} from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'technology',
  templateUrl: './technology.component.html',
  styleUrls: []
})
export class TechnologyComponent extends BaseComponent {
  public faPlay: IconDefinition = faPlayCircle;

  constructor(sharedService: SharedService
    , private titleService: Title,
    route: ActivatedRoute, router: Router) {
    super(sharedService, route, router);
    this.uid = TECHNOLOGY;
    this.titleService.setTitle(TECHNOLOGY_TITLE);
  }

  selectTab(tabName: string) {
    this.selectedTitle = tabName;

  }
}
