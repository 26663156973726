import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { BaseComponent } from '../base.component';
import SharedService from '../../shared/shared.service';
import { FACILITY_POLICY, FACILITY_POLICY_TITLE } from '../../shared/constants/pages.constants';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'facility-policy',
  templateUrl: './facility-policy.component.html',
  styleUrls: []
})
export class FacilityPolicyComponent extends BaseComponent {


  constructor(sharedService: SharedService
    , private titleService: Title,
    route: ActivatedRoute, router: Router) {
    super(sharedService, route, router);
    this.uid = FACILITY_POLICY;
    this.titleService.setTitle(FACILITY_POLICY_TITLE);
  }

  selectTab(tabName: string) {
    this.selectedTitle = tabName;

  }
}
