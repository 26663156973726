import { Component, Input, TemplateRef } from '@angular/core';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'accordion-control',
  templateUrl: './accordion-control.component.html',
  styleUrls: []
})
export class AccordionControlComponent extends BaseComponent {
  constructor() {
    super();
  }
}
