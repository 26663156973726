import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";

import SharedService from '../../shared/shared.service';
import { SPECIAL_STAINS } from '../../shared/constants/pages.constants';
import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-special-stains',
  templateUrl: './special-stains.component.html',
  styleUrls: ['./special-stains.component.css']
})
export class SpecialStainsComponent extends BaseComponent {

  constructor(sharedService: SharedService
    , private titleService: Title,
    route: ActivatedRoute, router: Router) {
    super(sharedService, route, router);
    this.uid = SPECIAL_STAINS;
    this.titleService.setTitle("SPECIAL STAINS");
  }

  selectTab(tabName: string) {
    this.selectedTitle = tabName;
  }
}
